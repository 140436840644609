import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import Layout from "../components/layout"
import styles from "./blog.module.scss"
import ArrowButton from "../components/blog/arrowButton"
import Footer from "../components/footer"
import ArticlesTable from "../components/blog/articlesTable"
import CategoryLabel from "../components/blog/categoryLabel"
import NewHeader from "../components/blog/header"
import Subscribe from "../components/blog/subscribe"
import throttle from "lodash.throttle"
import { graphql, Link } from "gatsby"
import ThreeDotsIndicator from "../components/blog/threeDotsIndicator"
import Search from "../components/blog/search"

const defaultImage =
  "https://blog.sidelinesports.com/wp-content/uploads/2020/12/XPS-profile-logo-official-new.png"

const Blog = ({ pageContext, data }) => {
  const allPosts = useMemo(() => data.posts.nodes, [data])
  const [posts, setPosts] = useState(allPosts.slice(1, 13))
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const articlesSection = useRef(null)
  const _ = data.texts._

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const loadMore = async () => {
    if (posts.length < allPosts.length) {
      setIsLoading(true)
      await delay(300)
      setPosts((prev) => [
        ...prev,
        ...allPosts.slice(prev.length + 1, prev.length + 4),
      ])
      setIsLoading(false)
    }
  }

  const loadMoreThrottled = useCallback(throttle(loadMore, 300), [])

  const handleScroll = () => {
    if (articlesSection.current) {
      const scrollPosition =
        document.body.scrollTop || document.documentElement.scrollTop
      const articlesBottom =
        articlesSection.current.clientHeight + articlesSection.current.offsetTop
      const startLoadingPosition = articlesBottom - window.innerHeight * 0.9
      const endLoadingPosition = articlesBottom - window.innerHeight * 0.2
      if (
        scrollPosition > startLoadingPosition &&
        scrollPosition < endLoadingPosition &&
        !isLoading
      ) {
        loadMoreThrottled()
      }
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout
      header={
        <NewHeader
          fixed
          translations={_}
          languageCode={pageContext.lang}
          search={() => setIsSearchOpen(true)}
        />
      }
      translations={_}
      addBlogPostContentType
      languageCode={pageContext.lang}
    >
      {allPosts.length ? (
        <>
          <div className={styles.landingSection}>
            <Link className={styles.imageWrapper} to={allPosts[0].path}>
              <div
                className={styles.imageContainer}
                style={{
                  backgroundImage: `url("${
                    allPosts[0].image || defaultImage
                  }")`,
                }}
              ></div>
            </Link>
            <div className={styles.contentWrapper}>
              <div className={[styles.tags, styles.desktop].join(" ")}>
                {allPosts[0].categories.map((c, i) => (
                  <CategoryLabel
                    key={`${i}-${c.id}`}
                    name={c.name}
                    path={c.path}
                    slug={c.slug}
                    transparent
                  />
                ))}
              </div>
              <Link to={allPosts[0].path}>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: allPosts[0].title,
                  }}
                ></h2>
              </Link>
              <div className={[styles.tags, styles.mobile].join(" ")}>
                {allPosts[0].categories.map((c, i) => (
                  <CategoryLabel
                    key={`${i}-${c.id}`}
                    name={c.name}
                    path={c.path}
                    slug={c.slug}
                    transparent
                  />
                ))}
              </div>
              <Link to={allPosts[0].path} className={styles.mobile}>
                <p className={styles.info}>
                  {new Date(allPosts[0].date).toLocaleDateString("en-US")}
                  <span className={styles.dot}>•</span>{" "}
                  {allPosts[0].readingTime} {_.blog.min_reading}
                </p>
              </Link>
              <Link to={allPosts[0].path}>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: allPosts[0].excerpt,
                  }}
                ></div>
              </Link>
              <Link to={allPosts[0].path} className={styles.desktop}>
                <p className={styles.info}>
                  {new Date(allPosts[0].date).toLocaleDateString("en-US")}
                  <span className={styles.dot}>•</span>{" "}
                  {allPosts[0].readingTime} {_.blog.min_reading}
                </p>
              </Link>
              <ArrowButton title={_.blog.read_more} href={allPosts[0].path} />
              <Link
                to={allPosts[0].path}
                className={[styles.postLink, styles.mobile].join(" ")}
              >
                {_.blog.read_more}
              </Link>
            </div>
          </div>
          <div className={styles.articlesSection} ref={articlesSection}>
            <ArticlesTable articles={posts.slice(0, 6)} translations={_} />
            {posts.length > 6 && (
              <>
                <Subscribe translations={_} narrow={true} />{" "}
                <ArticlesTable articles={posts.slice(6)} translations={_} />
                {posts.length < allPosts.length && (
                  <ThreeDotsIndicator animate={isLoading} />
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <p className={styles.noPosts}>
          There are no posts
          {pageContext.data?.name
            ? ` in ${pageContext.data.name} category`
            : null}
          .
        </p>
      )}
      {isSearchOpen && <Search close={() => setIsSearchOpen(false)} lang={pageContext.lang} />}
      <Subscribe translations={_} />
      <NewHeader
        translations={_}
        languageCode={pageContext.lang}
        search={() => setIsSearchOpen(true)}
      />
      <Footer translations={_} languageCode={pageContext.lang} />
    </Layout>
  )
}

export const query = graphql`
  query BlogListing($langFile: String, $postsFilter: BlogPostFilterInput) {
    posts: allBlogPost(filter: $postsFilter) {
      nodes {
        id: postId
        categories {
          id
          name
          path
          slug
        }
        date
        excerpt
        image: imageMedium
        path
        readingTime
        title
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        sports {
          basketball
          field_hockey
          floorball
          handball
          ice_hockey
          other_sports
          performance
          soccer
          tennis
          volleyball
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
        blog {
          blog_title
          all_title
          news_title
          features_title
          sports_title
          stories_title
          english
          czech
          min_reading
          read_more
          subscribe
          subscribe_newsletter
          email_placeholder
          xps_instagram
          follow_instagram
          related_articles
          subscribe_youtube
        }
      }
    }
  }
`

export default Blog
