import React from "react"
import arrowIcon from "../../images/blog/arrow.svg"
import styles from './arrowButton.module.scss'
import {Link} from 'gatsby'

const ArrowButton = ({ title, href }) => {
  return (
    <Link to={href} className={styles.arrowButton}>
        <div className={styles.buttonContent}>
            <p>{title}</p>
            <img src={arrowIcon} alt="arrow" />
        </div>
    </Link>
  )
}

export default ArrowButton
