import React from "react"
import styles from "./threeDotsIndicator.module.scss"

const ThreeDotsIndicator = ({ animate }) => {
  return (
    <div
      className={
        animate ? [styles.wrapper, styles.animated].join(" ") : styles.wrapper
      }
    >
      <div />
      <div />
      <div />
    </div>
  )
}

export default ThreeDotsIndicator
